<template>
  <div>
    <b-card>
      <!-- ORDER TRACKER -->
      <div v-if="currentRoute === 'order-tracker'">
        <div class="d-flex mt-1 justify-content-between">
          <!-- SEARCH INPUT -->
          <div class="d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search all field"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- ROUTE TRACKER -->
      <div v-if="currentRoute === 'route-tracker'">
        <div class="d-flex mt-1 justify-content-end">
          <!-- DROP DOWN -->
          <!-- <select
            @change="handleExport($event.target.value)"
            class="form-control form-select rounded text-primary border-primary mb-1 mr-1"
          >
            <option value="" disabled selected>Export</option>
            <option value="CSV">CSV</option>
            <option value="Excel">Excel</option>
          </select> -->
          <!-- SEARCH INPUT -->
          <div class="d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search all field"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
      </div>

      <vue-good-table
        :columns="tableData.columns"
        :rows="tableData.rows"
        :is-loading.sync="isLoading"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        style-class="vgt-table border-0 font-small-3"
      >
        <!-- TABLE LOADING -->
        <template slot="loadingContent">
          <Loading />
        </template>

        <!-- TABLE COLUMNS -->
        <template slot="table-column" slot-scope="props">
          <div class="my-2">
            {{ props.column.label }}
          </div>
        </template>

        <!-- TABLE ROWS -->
        <template slot="table-row" slot-scope="props">
          <div class="my-3">
            <!-- ORDER TRACKER -->
            <template v-if="currentRoute === 'order-tracker'">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToOrderSummary(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'order_type'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{ props.row.order_type }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'pickup'"
                class="d-flex justify-content-center"
              >
                <b v-if="props.row.pickup">
                  {{ props.row.pickup.area }}
                </b>
                <b v-else> N/A </b>
              </div>

              <div
                v-if="props.column.field === 'recipient.address_postcode'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{ props.row.recipient.address_postcode }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'order_placed_date'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{
                    !props.row.order_placed_date
                      ? ""
                      : props.row.order_placed_date.substr(0, 10)
                  }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'delivery_date'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{
                    !props.row.delivery_date
                      ? ""
                      : props.row.delivery_date.substr(0, 10)
                  }}
                </b>
              </div>

              <div
                v-if="props.column.field === 'quantity'"
                class="h-100 d-flex justify-content-center align-items-center"
              >
                <b>
                  {{ props.row.quantity }}
                </b>
              </div>

              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 'Active'"
                  block
                  variant="light-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Assigned'"
                  block
                  variant="dark-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Warehouse'"
                  block
                  variant="light-blue"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Transit'"
                  block
                  variant="yellow"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Out For Delivery'"
                  block
                  variant="purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Delivered'"
                  block
                  variant="turquoise"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Close Store'"
                  block
                  variant="maroon"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button v-else block variant="orange">
                  {{ props.row.status }}
                </b-button>
              </span>

              <div
                v-if="props.column.field === 'action'"
                class="d-flex justify-content-center"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <div v-if="props.row.status === 'Cancelled'">
                    <b-dropdown-item>
                      <span>No Action</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        updateStatus(props.row.id, props.row.originalIndex)
                      "
                    >
                      <span>Update Status</span>
                    </b-dropdown-item>
                  </div>
                  <div v-else-if="props.row.status === 'Generated'">
                    <b-dropdown-item
                      @click="
                        canceldhlgenerate(
                          props.row.shipment_id,
                          props.row.originalIndex
                        )
                      "
                    >
                      <span>Cancel</span>
                    </b-dropdown-item>
                  </div>
                  <div v-else>
                    <b-dropdown-item
                      @click="generateLabel(props.row, props.row.originalIndex)"
                    >
                      <span>Generate Label</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        updateStatus(props.row.id, props.row.originalIndex)
                      "
                    >
                      <span>Update Status</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="`/track-and-trace/order-tracking/${props.row.id}`"
                    >
                      <span>View Tracking</span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="`/order-summary/${props.row.id}`">
                      <span>Edit Order</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        cancelOrder(props.row.id, props.row.originalIndex)
                      "
                    >
                      <span>Cancel Order</span>
                    </b-dropdown-item>
                  </div>

                  <!-- <b-dropdown-item
                    v-show="props.row.status === 'Active'"
                    @click="assignDriver(props.row.id)"
                  >
                    <span>Assign Driver</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </div>
            </template>

            <!-- ROUTE TRACKER -->
            <template v-if="currentRoute === 'route-tracker'">
              <span v-if="props.column.field === 'route_id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToRouteSummary(false, props.row.route_id)"
                >
                  {{ props.row.route_id }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'orders'">
                <b-button
                  v-for="(order, index) in props.row.orders"
                  :key="index"
                  variant="gradient-primary"
                  block
                  @click="goToOrderSummary(order.id)"
                >
                  {{ order.id }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'driver.id'">
                <b-button variant="gradient-primary" block>
                  {{ props.row.driver.id }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'driver.name'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{ props.row.driver.name }}
                </b>
              </div>
              <div
                v-if="props.column.field === 'delivery_date'"
                class="d-flex justify-content-center"
              >
                <b>
                  {{
                    !props.row.delivery_date
                      ? ""
                      : props.row.delivery_date.substr(0, 10)
                  }}
                </b>
              </div>
              <div
                v-if="props.column.field === 'action'"
                class="d-flex justify-content-center"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="exportRoute(props.row.route_id)">
                    <span class="text-danger">
                      <feather-icon icon="DownloadIcon" />
                      Export Route
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="reassignDriver(props.row)"
                  >
                    <feather-icon icon="UserIcon" class="mr-50" />
                    <span>Reassign Driver</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="
                      deleteRoute(props.row.route_id, props.row.originalIndex)
                    "
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </div>
        </template>

        <!-- PAGINATION -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap p-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store/index";
import Loading from "./Loading.vue";

export default {
  components: {
    VueGoodTable,
    Loading,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    tableData: {
      type: Object,
      required: true,
    },
    currentRoute: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
  },

  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      dir: false,
      selected: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    // #region ORDER TRACKER
    generateLabel(order, index) {
      this.$emit("generateLabel", order, index);
    },

    updateStatus(id, index) {
      this.$emit("updateStatus", id, index);
    },

    cancelOrder(id, index) {
      this.$emit("cancelOrder", id, index);
    },

    assignDriver(id) {
      this.$emit("assignDriver", id);
    },
    // #endregion

    // #region ROUTE TRACKER
    goToRouteSummary(bool, id) {
      this.$router.push({
        name: "order-summary",
        params: {
          isEditable: bool,
          id,
        },
      });
    },

    reassignDriver(row) {
      this.$emit("reassignDriver", row);
    },

    goToOrderSummary(id) {
      this.$router.push({
        name: "order-summary",
        params: {
          id,
        },
      });
    },

    generatedhltracking(id) {
      this.generateDHLid = id.shipment_id;
      this.$refs.generatedhltracking.show();
    },
    generatedhllabel(id) {
      location.href = id;
    },
    canceldhlgenerate(id, index) {
      this.$emit("canceldhlgenerate", id, index);
    },
    exportRoute(id) {
      this.$emit("exportRoute", id);
    },
    deleteRoute(id, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to recover it.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$emit("deleteRoute", id, index);
        }
      });
    },
    // #endregion
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style scoped>
select {
  width: 120px;
  height: 40px;
}
</style>
