<template>
  <div>
    <!--Tabs-->
    <b-form-group class="col-sm-12 col-md-6 col-lg-3 px-0 mt-2">
      <b-card class="w-100" no-body>
        <flat-pickr
          v-model="selectedDate"
          class="form-control font-small-2"
          placeholder="Select Date"
          readonly="readonly"
        />
      </b-card>
    </b-form-group>
    <b-button
      @click="handleExport('csv')"
      variant="primary"
      class="btn btn-primary mb-2"
      >Export</b-button
    >
    <Table
      :is-loading="isLoading"
      :table-data="tableData"
      :current-route="this.$route.name"
      :selected-date="selectedDate"
      @deleteRoute="deleteRoute"
      @exportRoute="exportRoute"
      @reassignDriver="reassignDriver"
    />

    <!-- Assign Driver Modal -->
    <b-modal
      id="modal-3"
      ref="modal-3"
      centered
      ok-only
      ok-title="Save"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <div class="d-flex justify-content-center mt-2">
          <h2><b>Reassign Driver</b></h2>
        </div>
            <select
              v-model="form.driver_id"
              class="form-control form-select rounded"
              placeholder="Driver"
            >
              <option disabled selected value="">Select a driver</option>
              <option
                v-for="(driver, index) in driverList"
                :key="index"
                :value="driver.id"
              >
                {{ driver.name }}
              </option>
            </select>

            <div class="d-flex justify-content-center my-2">
              <b-button variant="gradient-primary" @click="assignDriver">
                SUBMIT
              </b-button>
            </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import Table from "../../../components/Table.vue";
import exportFromJSON from "export-from-json";

export default {
  components: {
    Table,
    flatPickr,
    ValidationProvider,
  },

  data() {
    return {
      isLoading: true,
      form: { driver_id: "" },
      driverList: "",
      selectedDate: new Date().toISOString().slice(0, 10),
      tableData: {
        columns: [
          {
            label: "Delivery ID",
            field: "route_id",
            tdClass: "text-center align-middle",
          },
          {
            label: "Order ID",
            field: "orders",
            tdClass: "text-center align-middle",
          },
          {
            label: "Driver ID",
            field: "driver.id",
            tdClass: "text-center align-middle",
          },
          {
            label: "Driver Name",
            field: "driver.name",
            tdClass: "text-center align-middle",
          },
          {
            label: "Delivery Date",
            field: "delivery_date",
            tdClass: "text-center align-middle",
          },
          {
            label: "Action",
            field: "action",
            tdClass: "text-center align-middle",
          },
        ],
        rows: [],
      },
    };
  },

  watch: {
    selectedDate(newDate, oldDate) {
      this.getRoutes(newDate);
    },
  },

  created() {
    this.getRoutes("");
  },

  methods: {
    getRoutes(value) {
      this.isLoading = true;
      this.$http.get(`routes/${this.selectedDate}`).then((response) => {
        if (response.data.status) {
          this.tableData.rows = [...response.data.routes];
        }
        this.isLoading = false;
      });
    },
    async exportRoute(Route) {
      console.log("test");
      const response = await this.$http.get(`export_route/${Route}`, {
        responseType: "blob",
      });
      const file = window.URL.createObjectURL(new Blob([response.data]));
      const anchor = document.createElement("a");
      anchor.href = file;
      anchor.setAttribute("download", `Route-${Route}-${Date.now()}.xlsx`);
      document.body.appendChild(anchor);
      anchor.click();
    },
    handleExport(type) {
      const fileName = `RouteList(${this.tableData.rows.delivery_date})`;
      const data = this.tableData.rows.map((item) => {
        console.log(item);
        return {
          "Delivery ID": item.route_id,
          "Order ID": item.orders.map((order) => order.id).join(", "),
          "Driver ID": item.driver.id,
          "Driver Name": item.driver.name,
          "Delivery Date": item.delivery_date,
        };
      });

      let exportType;
      switch (type) {
        case "xlsx":
          exportType = exportFromJSON.types.xls;
          exportFromJSON({ data, fileName, exportType });
          break;
        case "csv":
          exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          break;
      }
    },
    reassignDriver(row) {
      this.form.route_id = row.route_id;
      this.form.driver_id = row.driver.id;
      const requestData = { branch_id: [row.branch_id] };
      console.log(row)
      this.$http
        .post("get_driver_by_branch", requestData)
        .then((response) => (this.driverList = response.data.drivers));
      this.$refs["modal-3"].show();
    },
    assignDriver() {
      this.isLoading = true;
          this.$http
            .post("route/re_assign_route", this.form)
            .then((response) => {
              if (response.status == 200) {
                if (response.data.status == true) {
                  this.$swal({
                    title: "Driver Re-assigned !",
                    icon: "success",
                    text: response.data.message,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                    buttonsStyling: false,
                  });
                  this.getRoutes();
                } else {
                  this.$swal({
                    title: " Error !",
                    icon: "error",
                    text: response.data.message,
                    confirmButtonText: "Confirm",
                    customClass: {
                      confirmButton: "btn btn-warning",
                    },
                    buttonsStyling: false,
                  });
                }
              }
              this.$refs["modal-3"].hide();
              this.handlePreload();
            });
          this.isLoading = false;
    },
    deleteRoute(id, index) {
      this.$http.get(`delete_route?route_id=${id}`).then((response) => {
        if (response.status === 200) {
          this.$swal({
            icon: "success",
            title: "Deleted !",
            text: response.data.message,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.tableData.rows.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
